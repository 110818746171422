<template>
  <div>
    <Row>
      <i-col span="24">
        <Select v-model="selectPackage" @on-change="loadPublisherAmount">
            <Option v-for="item in packageArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>

    <p class="p-t-10">
      <span class="title">合计签约额</span> {{formatMoney(totalAmount)}}
    </p>

    <h2 class="text-center p-t-20">客户画像</h2>
    <Row class="p-t-10 p-b-5">
      <i-col span="24">
        <RadioGroup v-model="selectGbType" type="button" button-style="solid">
            <Radio v-for="(gbType,index) in gbTypeArray" :key="index" :label="gbType.key">{{gbType.label}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>

    <Row  class="table-title text-right" :gutter="8">
      <i-col span="9" class="text-left">名称</i-col>
      <i-col span="9">签约额(元)</i-col>
      <i-col span="6">占比</i-col>
    </Row>
    <Row v-for="(item,index) in portraitData" :key="index" :gutter="8" class="p-t-10 text-right">
      <i-col span="9" class="text-left">{{item.name}}</i-col>
      <i-col span="9">{{formatMoney(item.value)}}</i-col>
      <i-col span="6">{{item.rate}} %</i-col>
    </Row>
    <p v-if="portraitData.length===0" class="remark p-t-20 text-center">暂无数据</p>

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getPackageList, countAmountByPackage } from '@/api/dw/publisher'

export default {
  data () {
    return {
      packageArray: [],
      selectPackage: null,

      gbTypeArray: [
        { key: 'industry', label: '行业' },
        { key: 'brand', label: '品牌' }
      ],
      selectGbType: 'industry',

      totalAmount: 0,
      portraitData: []
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getPackageList().then(res => {
        this.packageArray = res
        if (res.length > 0) {
          this.selectPackage = res[0].id
          this.handleChangePackage()
        }
      })
    },
    handleChangePackage () {
      this.$store.commit('set_situation_gbId', this.selectPackage)
      this.loadPublisherAmount()
    },
    loadPublisherAmount () { // 获取签约汇总
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate,
        packageIds: this.selectPackage
      }

      countAmountByPackage(queryModel).then(res => {
        this.totalAmount = res.length > 0 ? res[0].value : 0
      })

      // 获取客户画像清单数据
      this.loadPublisherAmountByGbType()
    },
    loadPublisherAmountByGbType () { // 获取客户画像清单数据
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate,
        gbType: this.selectGbType,
        packageIds: this.selectPackage
      }

      countAmountByPackage(queryModel).then(res => {
        this.portraitData = res
      })
    },
    formatMoney (money) {
      return toMoney(money)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadPublisherAmount()
      }
    },
    selectGbType (val) {
      this.loadPublisherAmountByGbType()
    }
  }
}
</script>
